const defaultAmenities = [
  { name: 'Attached Private Garages' },
  { name: 'Balconies (Denver)' },
  // { name: 'Bike Room' },
  { name: 'Co-Working Space (Denver)' },
  { name: 'Concierge' },
  { name: 'Dog Run' },
  { name: 'EV Charging Station' },
  { name: 'Elevators' },
  { name: 'Fenced Community' },
  { name: 'Fenced Yards (Texas)' },
  // { name: 'Fitness Center' },
  { name: 'Floor-to-Ceiling Windows (Chicago)' },
  { name: 'Guest Suites' },
  { name: 'Hot Tub' },
  { name: 'Parking Garage (Denver)' },
  { name: 'Pet-Wash' },
  { name: 'Rooftop Deck' },
  { name: 'Storage Rooms' },
  { name: 'W/D Connections' },
  { name: 'W/D Included' },
  { name: 'W/D in Select Units' }
];
export default Object.freeze(defaultAmenities);
